import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import {
  BootstrapVue, BootstrapVueIcons, ModalPlugin, ToastPlugin,
} from 'bootstrap-vue'

import CountryFlag from 'vue-country-flag'

import './main/constants'
import './main/filters'

import { PrimeSurveys } from './main/primesurveys'

import axiosObject from './main/axios'

import router from './main/router'
import store from './store/index'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import App from './App.vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('country-flag', CountryFlag)
Vue.use(PrimeSurveys)
Vue.prototype.$http = axiosObject
store.dispatch('auth/init')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')
require('@core/scss/vue/libs/vue-flatpicker.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const PRIME_VUE_OBJECT = new Vue({
  store,
  router,
  render: h => h(App),
})
store.$app = PRIME_VUE_OBJECT

PRIME_VUE_OBJECT.$mount('#app')
window.PRIME_VUE_OBJECT = PRIME_VUE_OBJECT
