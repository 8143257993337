import Vue from 'vue'
import numeral from 'numeral'

Vue.filter('title', (value, replacer = '_') => {
  if (!value) return ''
  const valueString = value.toString()

  const arr = valueString.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
})

Vue.filter('formatCurrency', value => numeral(value).format('$ 0,0.00'))
Vue.filter('formatCount', value => numeral(value).format('0,0'))
Vue.filter('round2Decimal', value => numeral(value).format('0,0.00'))
Vue.filter('ratingText', value => {
  if (
    value === ''
        || value === null
        || typeof value === 'undefined'
  ) {
    return ''
  }
  return `${numeral(value).format('0.0')} ★`
})
Vue.filter('defaultWhenEmpty', (value, defaultValue) => {
  if (
    value === null
        || value === ''
        || typeof value === 'undefined'
  ) {
    return defaultValue
  }
  return value
})
Vue.filter('dotDateTimeShort', value => {
  if (
    typeof value !== 'string'
        || value === ''
        || (
          value.length !== 19
            && (
              value.indexOf('T') === -1
                || value.indexOf('Z') === -1
            )
        )
  ) {
    return value
  }
  const comparedDate = new Date()
  let dateObj = null
  if (
    value.indexOf('T') > 0
        && value.indexOf('Z') > 0
  ) {
    dateObj = new Date(value)
  } else {
    const dateAndTime = value.split(' ')
    dateObj = new Date(`${dateAndTime[0]}T${dateAndTime[1]}.000Z`)
  }

  let dateStr = `${((dateObj.getUTCDate() < 10) ? '0' : '') + dateObj.getUTCDate()}.${dateObj.getUTCMonth() < 9 ? '0' : ''}${dateObj.getUTCMonth() + 1}.${dateObj.getUTCFullYear()}`
  const timeStr = [dateObj.getUTCHours(), dateObj.getUTCMinutes()].map(v => (v < 10 ? `0${v}` : v)).join(':')
  if (
    comparedDate.toDateString() === dateObj.toDateString()
  ) {
    dateStr = 'Today'
  } else {
    comparedDate.setDate(comparedDate.getDate() - 1)
    if (
      comparedDate.toDateString() === dateObj.toDateString()
    ) {
      dateStr = 'Yesterday'
    }
  }
  return `${dateStr} ${timeStr}`
})
Vue.filter('dotDateTime', value => {
  if (
    typeof value !== 'string'
        || value === ''
        || (
          value.length !== 19
            && (
              value.indexOf('T') === -1
                || value.indexOf('Z') === -1
            )
        )
  ) {
    return value
  }
  const comparedDate = new Date()
  let dateObj = null
  if (
    value.indexOf('T') > 0
        && value.indexOf('Z') > 0
  ) {
    dateObj = new Date(value)
  } else {
    const dateAndTime = value.split(' ')
    dateObj = new Date(`${dateAndTime[0]}T${dateAndTime[1]}.000Z`)
  }

  let dateStr = `${((dateObj.getUTCDate() < 10) ? '0' : '') + dateObj.getUTCDate()}.${dateObj.getUTCMonth() < 9 ? '0' : ''}${dateObj.getUTCMonth() + 1}.${dateObj.getUTCFullYear()}`
  const timeStr = [dateObj.getUTCHours(), dateObj.getUTCMinutes(), dateObj.getUTCSeconds()].map(v => (v < 10 ? `0${v}` : v)).join(':')
  if (
    comparedDate.toDateString() === dateObj.toDateString()
  ) {
    dateStr = 'Today'
  } else {
    comparedDate.setDate(comparedDate.getDate() - 1)
    if (
      comparedDate.toDateString() === dateObj.toDateString()
    ) {
      dateStr = 'Yesterday'
    }
  }
  return `${dateStr} ${timeStr}`
})
Vue.filter('toHourMinuteSecond', totalSeconds => {
  // eslint-disable-next-line radix
  const totalSecondsInt = parseInt(totalSeconds)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(totalSecondsInt)) return '-'
  const secNum = parseInt(totalSecondsInt, 10)
  const hours = Math.floor(secNum / 3600)
  const minutes = Math.floor(secNum / 60) % 60
  const seconds = secNum % 60
  return [hours, minutes, seconds]
    .map(v => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':')
})

Vue.filter('formatPercent2Decimal', value => {
  let parseFloatValue = parseFloat(value)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parseFloatValue)) return '0.00 %'
  parseFloatValue *= 100.0
  return `${Vue.filter('round2Decimal')(parseFloatValue)} %`
})

Vue.filter('formatPercentInt', value => {
  let parseFloatValue = parseFloat(value)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parseFloatValue)) return '0 %'
  parseFloatValue *= 100.0
  return `${Math.round(parseFloatValue)} %`
})

Vue.filter('percentFromFloat', (value, addPercentCharacter = false) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return value
  const valueMultiply = value * 100
  return numeral(valueMultiply).format('0.00') + (
    addPercentCharacter
      ? ' %'
      : ''
  )
})

Vue.filter('formatDateRange', date => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
})
