// axios
import axios from 'axios'

const baseURL = process.env.VUE_APP_API_BASE_URL

const axiosObject = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axiosObject.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === window.CONST_HTTP_STATUS_CODE_ERROR_AUTHENTICATION) {
      console.log('UnAuthentication')
      window.PRIME_VUE_OBJECT.hideLoading()
      window.PRIME_VUE_OBJECT.$store.dispatch('auth/logout')
      window.PRIME_VUE_OBJECT.alertError('Unauthenticated!')
      // This function will cancel all the operations
      return new Promise(() => {
      })
    }

    if (error.response.status === window.CONST_HTTP_STATUS_CODE_METHOD_NOT_ALLOWED) {
      if (
        typeof error.response.data.user_information === 'object'
                && error.response.data.user_information !== null
      ) {
        window.PRIME_VUE_OBJECT.$store.dispatch('auth/updateAdminInfo', error.response.data.user_information)
        window.PRIME_VUE_OBJECT.hideLoading()
        return new Promise(() => {
        })
      }
      console.log('window.CONST_HTTP_STATUS_CODE_METHOD_NOT_ALLOWED')
      console.log(error.response.data)
      return new Promise(() => {
      })
    }

    return Promise.reject(error)
  },
)

axiosObject.updateAuthentication = apiToken => {
  axiosObject.defaults.headers.common.Authorization = `Bearer ${apiToken}`
}
axiosObject.removeAuthentication = () => {
  delete (axiosObject.defaults.headers.common.Authorization)
}

export default axiosObject
