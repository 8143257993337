window.CONST_ADMIN_ROLE_SUPER_ADMIN = 1
window.CONST_ADMIN_ROLE_ADMIN = 2
window.CONST_ADMIN_ROLE_STAFF = 3
window.CONST_HTTP_STATUS_CODE_ERROR_AUTHENTICATION = 401
window.CONST_HTTP_STATUS_CODE_METHOD_NOT_ALLOWED = 405

window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO = 'adminInfo'
window.CONST_LOCAL_STORAGE_KEY_API_TOKEN = 'apiToken'
window.CONST_LOCAL_STORAGE_KEY_ORIGIN_ADMIN_INFO = 'orgAdminInfo'
window.CONST_LOCAL_STORAGE_KEY_ORIGIN_API_TOKEN = 'orgApiToken'

window.MENU_REFRESH_NUMBER = 1
