import httpObject from './axios'
import primeConstants from './primeConstants'

export default {
  namespaced: true,
  state: {
    AppActiveAdmin: null,
    isLoggedIn: false,
  },
  mutations: {
    UPDATE_ADMIN_INFO(state, payload) {
      state.AppActiveAdmin = payload
      state.isLoggedIn = true
    },
    CLEAR_ADMIN_INFO(state) {
      state.AppActiveAdmin = null
      state.isLoggedIn = false
    },
  },
  actions: {
    init({ dispatch, commit }) {
      // Load user from local storage
      const userInfo = JSON.parse(localStorage.getItem(primeConstants.LOCAL_STORAGE_USER_KEY))
      const apiToken = localStorage.getItem(primeConstants.LOCAL_STORE_API_TOKEN)
      if (
        userInfo
                && apiToken
      ) {
        commit('UPDATE_ADMIN_INFO', userInfo)
        httpObject.updateAuthentication(apiToken)
      }

      httpObject.interceptors.response.use(
        response => response,
        error => {
          if (error.response.status === 401) {
            dispatch('logout')
          }
          this.$app.hideLoading()
          if (
            typeof error.response.data === 'object'
                        && error.response.data !== null
          ) {
            this.$app.alertError(error.response.data)
          }
          return error
        },
      )
    },
    updateApiToken(apiToken) {
      localStorage.setItem(primeConstants.LOCAL_STORE_API_TOKEN, apiToken)
      httpObject.updateAuthentication(apiToken)
    },
    logout({ commit, getters }) {
      if (getters.isLoggedIn) {
        commit('CLEAR_ADMIN_INFO')
        httpObject.post('/api/auth/logout')
        httpObject.removeAuthentication()
        localStorage.removeItem(primeConstants.LOCAL_STORAGE_USER_KEY)
        localStorage.removeItem(primeConstants.LOCAL_STORE_API_TOKEN)
        this.$app.$router.push({ path: '/pages/login' })
      }
    },
    login({ commit }, payload) {
      const apiToken = payload.accessToken
      const admin = payload.publisherInfo
      commit('UPDATE_ADMIN_INFO', admin)
      localStorage.setItem(primeConstants.LOCAL_STORAGE_USER_KEY, JSON.stringify(admin))
      localStorage.setItem(primeConstants.LOCAL_STORE_API_TOKEN, apiToken)
      httpObject.updateAuthentication(apiToken)
      setTimeout(() => {
        this.$app.$router.push({ path: '/' })
      }, 1000)
    },
    updateProfile({ commit }, profile) {
      commit('UPDATE_ADMIN_INFO', profile)
      localStorage.setItem(primeConstants.LOCAL_STORAGE_USER_KEY, JSON.stringify(profile))
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    appActiveUser: state => state.AppActiveAdmin,
  },
}
