/*= ========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // =============================================================================
    // Theme Routes
    // =============================================================================
    {
      path: '/',
      name: 'dashboard',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        main: true,
      },
      component: () => import('@ps_views/Dashboard.vue'),
    },
    {
      path: '/profile',
      name: 'profile',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/user-settings/UserSettings.vue'),
    },
    {
      path: '/apps',
      name: 'apps',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Apps',
        breadcrumb: [
          {
            text: 'Apps',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/apps/Apps.vue'),
    },
    {
      path: '/apps/edit/:appHash',
      name: 'app-edit',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Edit App',
        breadcrumb: [
          {
            text: 'App',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/apps/edit/AppEdit.vue'),
    },
    {
      path: '/audiences',
      name: 'audiences',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Audiences',
        breadcrumb: [
          {
            text: 'Audiences',
            active: true,
          },
        ],
        loading: false,
      },
      component: () => import('@ps_views/Audiences.vue'),
    },
    {
      path: '/payments',
      name: 'payments',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Payment',
        breadcrumb: [
          {
            text: 'Payment',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/Payments.vue'),
    },
    {
      path: '/statistics',
      name: 'statistics',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Statistics',
        breadcrumb: [
          {
            text: 'Statistics',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/Statistics.vue'),
    },
    {
      path: '/logs/click-log',
      name: 'click-log',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Click Log',
        breadcrumb: [
          {
            text: 'Logs',
          },
          {
            text: 'Click Log',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/logs/ClickLog.vue'),
    },
    {
      path: '/logs/postback-log',
      name: 'postback-log',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Postback Log',
        breadcrumb: [
          {
            text: 'Logs',
          },
          {
            text: 'Postback Log',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/logs/PostbackLog.vue'),
    },
    {
      path: '/developers/integration',
      name: 'developers-integration',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Developers integration',
        breadcrumb: [
          {
            text: 'Developer',
          },
          {
            text: 'Integration',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/developers/Integration.vue'),
    },
    {
      path: '/pages/login',
      name: 'page-login',
      meta: {
        loading: false,
        layout: 'full',
      },
      component: () => import('@ps_views/pages/Login.vue'),
    },
    {
      path: '/pages/register',
      name: 'page-register',
      meta: {
        loading: false,
        layout: 'full',
      },
      component: () => import('@ps_views/pages/Register.vue'),
    },
    {
      path: '/pages/error-404',
      name: 'page-error-404',
      meta: {
        layout: 'full',
        loading: false,
      },
      component: () => import('@ps_views/pages/Error404.vue'),
    },
    {
      path: '*',
      redirect: '/error-404',
    },
    {
      path: '/logs/transaction-log',
      name: 'transaction-log',
      meta: {
        requiresAuth: true,
        // pageTitle: 'Postback Log',
        breadcrumb: [
          {
            text: 'Logs',
          },
          {
            text: 'Transaction Log',
            active: true,
          },
        ],
      },
      component: () => import('@ps_views/logs/TransactionLog.vue'),
    },
  ],
})

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const isUserLoggedIn = store.getters['auth/isLoggedIn']
  const redirectPagesIfLoggedIn = [
    'page-login',
    'page-register',
    'page-forgot-password',
  ]

  if (redirectPagesIfLoggedIn.indexOf(to.name) >= 0) {
    if (
      isUserLoggedIn && (
        to.name !== 'page-login'
                || window.location.href.indexOf('alk=') === -1
      )
    ) {
      router.push({ name: 'dashboard' })
    } else {
      return next()
    }
  }

  if (to.meta.loading !== false) {
    router.app.showLoading()
  }

  // const pagesWithoutAuthentication = [
  //   'page-error-404',
  //   'page-error-500',
  //   'page-comingsoon',
  // ]

  if (
    to.meta.requiresAuth !== true
  ) {
    router.app.hideLoading()
    return next()
  }

  if (!isUserLoggedIn) {
    router.push({ path: '/pages/login', query: { to: to.path } })
  } else {
    return next()
  }
})

export default router
